.ai-chat-bot {
  &__wrapper {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr min-content;

    &_open {
      gap: 12px;
    }
  }

  &__children_open {
    border-right: 1px solid #d9d9d9;
  }

  &__container {
    position: relative;
    z-index: 1050;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 550px;
    height: 100%;
    border-left: 1px solid #d9d9d9;
    background: #fff;

    &_fullscreen {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 0;
      transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 12px;
    border-bottom: 1px solid #d9d9d9;
    color: white;
  }

  &__header-title {
    display: flex;
    align-items: center;
    color: #262626;
    white-space: nowrap;
    font-weight: 500;
    gap: 8px;
  }

  &__header-title-icon {
    width: 24px;
    height: 24px;
  }

  &__header-actions {
    display: flex;
    justify-content: space-between;
    width: min-content;
    gap: 8px;
  }

  &__suggestion-chips {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 8px;
    gap: 8px;

    .ant-btn {
      padding: 4px 12px;
      height: auto;
      border-color: #d9d9d9;
      border-radius: 16px;
      font-size: 13px;
    }
  }

  &__header-actions .ant-btn:hover {
    background: rgb(255 255 255 / 20%);
  }

  &__suggestion-chips .ant-btn:hover {
    border-color: #722ed1;
    color: #722ed1;
  }

  &__avatar {
    background-color: #722ed1;

    &_assistant {
      background-color: #722ed1;
      color: white;
    }

    &_user {
      background-color: #f0f2f5;
      color: #722ed1;
    }
  }

  &__messages {
    flex: 1;
    overflow-y: auto;
    padding: 16px 8px;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background-color: rgb(0 0 0 / 20%);
    }
  }

  &__empty-img {
    width: 100px;
    height: 100px;
  }

  &__empty-state {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px;
    width: 100%;

    &-title {
      margin: 0;
      color: #9254de;
      font-weight: 500;
      font-size: 24px;
    }

    &-subtitle {
      margin: 0 0 16px;
      color: #666;
      font-weight: 400;
      font-size: 20px;
    }

    &-suggestions {
      display: flex;
      flex-direction: column;
      margin-top: 16px;
      max-width: 600px;
      width: 100%;
      gap: 4px;
    }

    &-suggestion {
      padding: 16px;
      border-radius: 0;
      background: #f5f5f5;
      cursor: pointer;
      transition: all 0.2s ease;

      &:hover {
        background: #e6e6e6;
      }

      &_first {
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      &_last {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &-content {
        display: flex;
        align-items: center;
        gap: 12px;
      }

      &-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        color: #9254de;
        font-size: 18px;
      }

      &-text-container {
        display: flex;
        flex-direction: column;
        gap: 2px;
      }

      &-text {
        color: #333;
        font-weight: 500;
        font-size: 16px;
      }

      &-description {
        color: #666;
        font-size: 14px;
      }
    }
  }

  &__message-container {
    display: flex;
    margin-bottom: 16px;

    &_user {
      flex-direction: row-reverse;
    }
  }

  &__message-avatar {
    flex-shrink: 0;
    margin: 0 12px;
    min-width: 30px;
  }

  &__error {
    margin-bottom: 16px;
    padding: 12px 16px;
    border: 1px solid #ffccc7;
    border-radius: 8px;
    background: #fff2f0;

    p {
      margin-bottom: 8px;
      color: #ff4d4f;
    }
  }

  &__message {
    padding: 5px 2px;
    max-width: 80%;
    border-radius: 12px;
    background: white;
    color: #262626;

    p {
      margin-bottom: 0.5em;
    }

    code {
      padding: 2px 4px;
      border-radius: 4px;
      font-family: monospace;
    }

    pre {
      overflow-x: auto;
      padding: 8px;
      border-radius: 4px;

      code {
        padding: 0;
        background: transparent;
      }
    }

    &_skeleton {
      width: 100%;
    }

    &_loading {
      min-width: 60px;
    }

    &-actions {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
    }
  }

  &__error p &:last-child {
    margin-bottom: 0;
    color: #8c8c8c;
  }

  &__message p &:last-child {
    margin-bottom: 0;
  }

  &__recording-indicator {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: #f9f0ff;
    gap: 4px;

    span {
      color: #722ed1;
      font-weight: 500;
      font-size: 14px;
    }
  }

  &__recording-pulse {
    position: relative;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #722ed1;
    animation: pulse 1.5s infinite;
  }

  &__input-wrapper {
    position: relative;
    padding: 16px;
    border-top: 1px solid #d9d9d9;
    background: #fff;

    textarea {
      padding-top: 12px;
      padding-right: 80px;
      min-height: 48px !important;
      max-height: 200px;
      border-radius: 8px;
      font-size: 14px;
      line-height: 1.5;
      resize: none;

      &:focus {
        border-color: #722ed1;
        box-shadow: none;
      }

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 3px;
        background-color: rgb(0 0 0 / 20%);
      }
    }
  }

  &__input-actions {
    position: absolute;
    right: 24px;
    bottom: 24px;
    display: flex;
    gap: 8px;
  }

  &__send-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 32px;
    height: 32px;
    border-color: #722ed1;
    border-radius: 50%;
    background-color: #722ed1;

    .anticon {
      font-size: 14px;
    }

    &:hover,
    &:focus {
      border-color: #531dab;
      background-color: #531dab;
    }
  }

  &__voice-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    transition: all 0.2s ease;

    .anticon {
      font-size: 16px;
    }

    &_active {
      background-color: #f9f0ff;

      .anticon {
        color: #722ed1;
      }
    }

    &:hover {
      background-color: #f9f0ff;

      .anticon {
        color: #722ed1;
      }
    }
  }

  &__code-block {
    position: relative;
    overflow: hidden;
    margin: 8px 0;
    border-radius: 6px;
    background: #1e1e1e;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 8px 12px;
      border-bottom: 1px solid #3d3d3d;
      background: #2d2d2d;
    }

    &-content {
      overflow-x: auto;
      margin: 0;
      padding: 12px;
      color: #d4d4d4;
      white-space: pre-wrap;
      word-break: break-word;
      font-size: 14px;
      font-family: 'Fira Code', monospace;
      line-height: 1.5;
    }
  }

  &__copy-button {
    color: #8c8c8c;
    transition: all 0.3s;

    &:hover {
      color: #fff;
    }

    &.copied {
      color: #52c41a;
    }
  }

  &__join-actions-container {
    margin-top: 16px;
    padding: 8px;
    border-radius: 8px;
    background: #f9f0ff;

    .ai-chat-bot__message {
      background: transparent;
      box-shadow: none;
    }

    p {
      margin-bottom: 12px;
      color: #722ed1;
      font-weight: 500;
    }
  }

  &__metric-actions {
    display: flex;
    margin-top: 12px;
    gap: 8px;
  }

  &__join-actions {
    margin: 16px 0;
    padding: 16px;
    border-radius: 8px;
    background: #f9f0ff;
  }

  &__join-actions-message {
    margin-bottom: 12px;
    color: #722ed1;
    font-weight: 500;
  }
}

@keyframes typing {
  0%,
  80%,
  100% {
    opacity: 0.6;
    transform: scale(0.6);
  }

  40% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgb(114 46 209 / 40%);
  }

  70% {
    box-shadow: 0 0 0 10px rgb(114 46 209 / 0%);
  }

  100% {
    box-shadow: 0 0 0 0 rgb(114 46 209 / 0%);
  }
}
